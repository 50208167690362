<template>
    <div>
        <v-navigation-drawer v-model="showMobileDrawer" absolute temporary right>
            <v-list nav dense>
                <v-list-item-group v-model="group" active-class="deep-purple--text text--accent-4">
                    <v-list-item v-if="accountSetup" @click="$router.push('/dashboard')">
                        <v-list-item-title>Your Accounts</v-list-item-title>
                    </v-list-item>

                    <v-list-item  v-if="accountSetup" @click="$router.push('/offers')">
                        <v-list-item-title>Offers</v-list-item-title>
                    </v-list-item>

                    <v-list-item  v-if="accountSetup" @click="$router.push('/settings')">
                        <v-list-item-title>Settings</v-list-item-title>
                    </v-list-item>

                    <v-list-item  v-if="accountSetup" @click="$router.push('/help-and-support')">
                        <v-list-item-title>Help and Support</v-list-item-title>
                    </v-list-item>

                    <v-list-item @click="logout">
                        <v-list-item-title>Logout</v-list-item-title>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-navigation-drawer>
        <v-app-bar :color="$vuetify.theme.dark ? 'dark' : 'white'" :dark="$vuetify.theme.dark"
            class="px-sm text-left shadow-sm" height="75" app clipped-left>
            <!-- <v-app-bar-nav-icon
                v-ripple="{class: 'primary--text'}"
                @click="toggleVerticalSaasSidebarDrawer"
            /> -->
            <v-progress-linear :active="getThemeMode.isLoading" :indeterminate="getThemeMode.isLoading" absolute bottom
                color="primary" />


            <v-toolbar-title>
                <img @click.prevent="$router.push('/')" height="20" class="logo-img"
                    :src="require('@/assets/images/logo.svg')" />
            </v-toolbar-title>

            <v-spacer />

            <!-- <v-badge
                bordered
                overlap
                content="3"
                color="red"
                offset-x="22"
                offset-y="22"
            >
                <v-btn icon @click="notificationDrawer = !notificationDrawer">
                    <v-icon>mdi-bell</v-icon>
                </v-btn>
            </v-badge> -->



            <!-- <v-btn icon @click="searchDrawer = !searchDrawer">
                <v-icon>mdi-magnify</v-icon>
            </v-btn> -->

            <!-- Mobile -->
            <div class="d-block d-sm-none">
                <v-app-bar-nav-icon @click.stop="showMobileDrawer = !showMobileDrawer"></v-app-bar-nav-icon>
            </div>
            <!-- / Mobile -->

            <!-- Desktop -->
            <div class="d-none d-sm-block">
                <v-chip v-if="accountSetup" pill class="transparent py-5" @click="$router.push('/dashboard')">
                    Your Accounts
                </v-chip>

                <v-chip v-if="accountSetup" pill class="transparent py-5" @click="$router.push('/offers')">
                    Offers
                </v-chip>

                <v-chip v-if="accountSetup" pill class="transparent py-5" @click="$router.push('/settings')">
                    Settings
                </v-chip>

                <v-chip v-if="accountSetup" pill class="transparent py-5" @click="$router.push('/help-and-support')">
                    Help and Support
                </v-chip>

                <v-chip pill class="transparent py-5" @click="logout">
                    Logout
                </v-chip>
            </div>
            <!-- / Desktop -->

        </v-app-bar>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import UserDrawer from "../common-drawer/UserDrawer.vue";
import NotificationDrawer from "../common-drawer/NotificationDrawer.vue";
import SearchDrawer from "../common-drawer/SearchDrawer.vue";
export default {
    name: "VerticallAppBar",
    components: {
        UserDrawer,
        NotificationDrawer,
        SearchDrawer,
    },
    computed: {
        ...mapGetters(["getThemeMode"]),

        ...mapGetters("auth", {
            user: "user",
        }),

        ...mapGetters("accounts", {
            numberOfAccounts: "numberOfAccounts"
        }),

        accountSetup() {
            return this.user && this.user.account_setup && this.user.id_verified && this.user.approved && (this.numberOfAccounts > 0);
        },
    },
    data() {
        return {
            userDrawer: false,
            notificationDrawer: false,
            searchDrawer: false,

            showMobileDrawer: false
        };
    },
    methods: {
        ...mapActions("auth", {
            logout: "logout",
        }),

        ...mapActions(["changeVerticalSaasSidebarDrawer"]),
        toggleVerticalSaasSidebarDrawer() {
            this.changeVerticalSaasSidebarDrawer();

            // this.$emit("update:mini-variant");
            // console.log("check");
        },
    },
};
</script>

<style scoped>
.logo-img {
    margin-top: 4px;
    cursor: pointer;
}
</style>
